<div class="height-emulator d-none d-lg-block"></div>
<footer class="footer-area footer-fixed">
  <!-- Footer Top -->
  <div class="footer-top ptb_100">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Logo -->
            <a class="navbar-brand" href="#">
              <img class="logo" src="assets/img/logo.png" alt="" />
            </a>
            <p class="mt-2 mb-3">
              Un enfoque de Wellness integral y refrescante para desarrollar una
              cultura de salud y bienestar en tu organización.
            </p>
            <div class="terms">
              <a href="https://www.bonavidawellness.com/terms"> Términos </a>
              <br />
              <a href="https://www.bonavidawellness.com/privacy">
                Política de privacidad
              </a>
            </div>
            <!-- Social Icons -->
            <div class="social-icons d-flex">
              <a
                class="facebook"
                href="https://www.facebook.com/Bonavida-Wellness-105025091955784"
              >
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                class="google-plus"
                href="https://www.instagram.com/bonavidawellness/"
              >
                <i class="fab fa-instagram"></i>
                <i class="fab fa-instagram"></i>
              </a>
              <!-- <a class="google-plus" href="#">
                                <i class="fab fa-google-plus-g"></i>
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                            <a class="vine" href="#">
                                <i class="fab fa-vine"></i>
                                <i class="fab fa-vine"></i>
                            </a> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3"></div>
        <div class="col-12 col-sm-6 col-lg-3"></div>

        <!-- <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-items">
                        <h3 class="footer-title mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a href="#">Home</a></li>
                            <li class="py-2"><a href="#">About Us</a></li>
                            <li class="py-2"><a href="#">Services</a></li>
                            <li class="py-2"><a href="#">Blog</a></li>
                            <li class="py-2"><a href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-items">
                        <h3 class="footer-title mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a href="#">FAQ</a></li>
                            <li class="py-2"><a href="#">Privacy Policy</a></li>
                            <li class="py-2"><a href="#">Support</a></li>
                            <li class="py-2"><a href="#">Terms &amp; Conditions</a></li>
                            <li class="py-2"><a href="#">Contact</a></li>
                        </ul>
                    </div>
                </div> -->
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-2 mt-5">Download</h3>
            <!-- Store Buttons -->
            <div
              class="button-group store-buttons store-black d-flex flex-wrap"
            >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.bonavida.gt"
              >
                <img src="assets/img/google-play-black.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://apps.apple.com/lv/app/bonavida/id1598738341"
              >
                <img src="assets/img/app-store-black.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Bottom -->
  <!-- <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <div class="copyright-left">&copy; Copyrights 2021 sApp All rights reserved.</div>
                        <div class="copyright-right">Made with <i class="fas fa-heart"></i> By <a href="#">Themeland</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</footer>
