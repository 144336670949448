<section id="contact" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="">¿Te gustaría implementar Bonavida?</h2>
                    <p class="d-none d-sm-block mt-4">Agenda un tiempo con nosotros para conocer más sobre tu
                        organización y sus necesidades, y así podamos asesorarlos y ofrecerles un programa a la medida.
                    </p>
                    <p class="d-block d-sm-none mt-4">Agenda un tiempo con nosotros para conocer más sobre tu
                        organización y sus necesidades, y así podamos asesorarlos y ofrecerles un programa a la medida.
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-5">
                <!-- Contact Us -->
                <div class="contact-us">
                    <ul>
                        <li class="py-2">
                            <a class="media" href="#">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-home"></i>
                                </div>
                                <span class="media-body align-self-center">Servimos a toda Latinoamérica</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" href="#">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <span class="media-body align-self-center">(+502) 4013-1462</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" href="#">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <span class="media-body align-self-center"><a
                                        href="mailto:comercial@bonavidawellness.com">comercial@bonavidawellness.com</a></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-md-6 pt-4 pt-md-0">
                <div class="contact-box text-center">
                    <form id="contact-form">
                        <div class="row">
                            <div class="col-12">
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name"
                                        required="required">
                                </div> -->
                                <div class="form-group">
                                    <input type="email" class="form-control" [(ngModel)]="email" name="email"
                                        placeholder="Correo electrónico" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" [(ngModel)]="message" name="message"
                                        placeholder="Mensaje" required="required"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button (click)="sendMail()" class="btn btn-lg btn-block mt-3">
                                    <div>
                                        <div *ngIf="!isSending">
                                            <span class="text-white pr-3"><i class="fas fa-paper-plane"></i>
                                            </span>Enviar mensaje
                                        </div>
                                        <div *ngIf="isSending">
                                            <span class="text-white pr-3"><i class="fas fa-spinner"></i>
                                            </span>Enviando
                                        </div>
                                    </div>
                                </button>
                                <div *ngIf="isSent" class="mt-3">
                                    Hemos recibido tu correo, en breve nos pondremos en contacto contigo.
                                </div>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section>