import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tenminutosportubienestar",
  templateUrl: "./tenminutosportubienestar.component.html",
  styleUrls: ["./tenminutosportubienestar.component.css"],
})
export class TenminutosportubienestarComponent implements OnInit {
  showContent = false;
  constructor() {
    setTimeout(() => {
      this.showContent = true;
    }, 1000);
  }

  ngOnInit(): void {}
}
