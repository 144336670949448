<div *ngIf="!showContent">
    <div class="container">
        <div class="row">
            <div id="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
            </div>
        </div>
    </div>
</div>

<div class="main" [hidden]="!showContent">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-welcome-one></app-welcome-one>
    <app-discover-one></app-discover-one>
    <app-feature-three></app-feature-three>
    <app-feature-one></app-feature-one>
    <app-work></app-work>
    <app-screenshot-one></app-screenshot-one>
    <app-review-one></app-review-one>
    <app-download></app-download>
    <app-contact></app-contact>
    <app-footer-one></app-footer-one>
</div>