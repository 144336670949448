import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactosComponent } from "./contactos/contactos.component";
import { FuncionComponent } from "./funcion/funcion.component";
import { InterfaceComponent } from "./interface/interface.component";
import { OpinionComponent } from "./opinion/opinion.component";
import { PillarComponent } from "./pillar/pillar.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { SolucionComponent } from "./solucion/solucion.component";
import { TenminutosportubienestarComponent } from "./tenminutosportubienestar/tenminutosportubienestar.component";
import { ThemeOneComponent } from "./themes/theme-one/theme-one.component";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { DeleteComponent } from "./components/delete/delete.component";

const routes: Routes = [
  { path: "", component: ThemeOneComponent },
  { path: "parte", component: PillarComponent },
  { path: "solucion", component: SolucionComponent },
  { path: "servicios", component: ServiciosComponent },
  { path: "funcion", component: FuncionComponent },
  { path: "interface", component: InterfaceComponent },
  { path: "opinion", component: OpinionComponent },
  { path: "contact", component: ContactosComponent },
  {
    path: "10minutosportubienestar",
    component: TenminutosportubienestarComponent,
  },
  {
    path: "terms",
    component: TermsComponent,
  },
  {
    path: "termsconditions",
    component: TermsComponent,
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "delete",
    component: DeleteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
