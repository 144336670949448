<section class="section download-area overlay-dark ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2 class="text-white">¿Poco tiempo para desarrollar y/o manejar un programa?
                    </h2>
                    <p class="text-white my-3 d-none d-sm-block">¿Aún no estás muy familiarizado con un programa de
                        wellness o no tienes tiempo para administrarlo todo? No te preocupes, nuestros expertos pueden
                        hacerlo por ti. Trabaja junto a nuestro experto para poder diseñar, implementar y monitorear un
                        programa anual de Wellness a la medida de sus necesidades.
                    </p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex justify-content-center">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bonavida.gt">
                            <img src="assets/img/google-play.png" alt="">
                        </a>
                        <a target="_blank" href="https://apps.apple.com/lv/app/bonavida/id1598738341">
                            <img src="assets/img/app-store.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>