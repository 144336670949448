<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        Una experiencia <span class="text-primary">increíble</span>
                    </span>
                    <h2 class="text-capitalize">Interface simple, atractiva y fácil de usar</h2>
                    <p class="d-none d-sm-block mt-4">Ofrece un programa de wellness integral a tus colaboradores,
                        diseñado por expertos y que da resultados positivos y medibles. Con nuestra innovadora y
                        motivadora Bonavida App, podrán iniciar su camino de bienestar integral y todo al alcance de sus
                        manos. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc1.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc2.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc3.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc4.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc5.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc6.jpg" alt="">
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/sc7.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>