import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeOneComponent } from './components/welcome/welcome-one/welcome-one.component';
import { FeatureOneComponent } from './components/features/feature-one/feature-one.component';
import { DiscoverOneComponent } from './components/discover/discover-one/discover-one.component';
import { WorkComponent } from './components/work/work.component';
import { ScreenshotOneComponent } from './components/screenshots/screenshot-one/screenshot-one.component';
import { DownloadComponent } from './components/download/download.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterOneComponent } from './components/footer/footer-one/footer-one.component';
import { ScrollupComponent } from './components/scrollup/scrollup.component';
import { ThemeOneComponent } from './themes/theme-one/theme-one.component';
import { ReviewOneComponent } from './components/reviews/review-one/review-one.component';
import { FeatureThreeComponent } from './components/features/feature-three/feature-three.component';
import { HeaderOneComponent } from './components/header/header-one/header-one.component';
import { PillarComponent } from './pillar/pillar.component';
import { SolucionComponent } from './solucion/solucion.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { FuncionComponent } from './funcion/funcion.component';
import { InterfaceComponent } from './interface/interface.component';
import { OpinionComponent } from './opinion/opinion.component';
import { ContactosComponent } from './contactos/contactos.component';
import { TenminutosportubienestarComponent } from './tenminutosportubienestar/tenminutosportubienestar.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DeleteComponent } from './components/delete/delete.component';

@NgModule({
  declarations: [
    AppComponent,
    ScrollupComponent,
    ThemeOneComponent,
    WelcomeOneComponent,
    HeaderOneComponent,
    DiscoverOneComponent,
    FeatureThreeComponent,
    FeatureOneComponent,
    WorkComponent,
    ScreenshotOneComponent,
    ReviewOneComponent,
    DownloadComponent,
    ContactComponent,
    FooterOneComponent,
    PillarComponent,
    SolucionComponent,
    ServiciosComponent,
    FuncionComponent,
    InterfaceComponent,
    OpinionComponent,
    ContactosComponent,
    TenminutosportubienestarComponent,
    TermsComponent,
    PrivacyComponent,
    DeleteComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }