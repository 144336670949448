<section id="features" class="section features-area style-two overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        Servicios Corporativos
                    </span>
                    <h2>Conoce nuestra variedad de Servicios</h2>
                    <p class="d-none d-sm-block mt-4">Además de nuestra oferta digital, nuestra variedad de servicios
                        personalizados ofrecen un asesoramiento 1 a 1 que ayuda a los colaboradores a alcanzar sus
                        objetivos de salud y bienestar de manera guiada.</p>
                </div>
            </div>
        </div>

        <div class="vimeo-player">
            <iframe class="vimeo-iframe" src="https://player.vimeo.com/video/747229733?h=1f77af90d6" width="640"
                height="360" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 p-5 wow fadeInLeft" data-wow-delay="0.4s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-sm" src="assets/img/featured_image_1.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Nutrición</h3>
                        <p>Citas periódicas con nuestro equipo de nutricionistas expertas, además de charlas, talleres
                            de cocina saludable y sesiones de seguimiento.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 p-5 wow fadeInUp" data-wow-delay="0.2s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-sm" src="assets/img/featured_image_2.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Mindfulness</h3>
                        <p>Charlas y talleres de mindfulness y técnicas de respiración para ayudar a los colaboradores a
                            reducir los niveles de estrés y ansiedad, aumentar su capacidad de atención y enfoque y
                            aprender a vivir y disfrutar el momento presente.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 p-5 wow fadeInRight" data-wow-delay="0.4s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-sm" src="assets/img/featured_image_3.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fitness y Pausas Activas</h3>
                        <p>Variedad de Clases de fitness a la medida de las necesidades, niveles y gustos de los
                            colaboradores. Asimismo podrán reducir el sedentarismo y elevar el nivel de energía a través
                            de nuestras sesiones de Pausas Activas diseñadas por expertos.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>