<div class="m-4">
  Cláusula 1. Aceptación de los Términos y Condiciones de Uso: El sitio web
  https://bonavidawellness.com/ es administrado por Bonavida (en adelante, la
  “Compañía”). Los siguientes términos y condiciones de uso rigen la
  participación que efectúe la persona (el “Usuario”) en el programa de Wellness
  de Bonavida (el “programa”), así como en este sitio web, y establecen un
  acuerdo obligatorio, según las disposiciones del presente convenio, entre
  Bonavida y el Usuario (las “Partes”). La participación en el programa y el uso
  del sitio web, constituye la aceptación expresa de los siguientes términos y
  condiciones de uso (los “Términos y Condiciones de Uso”). Bonavida se reserva
  el derecho de hacer cambios en el programa y en el sitio web, así como
  modificar estos Términos y Condiciones de Uso y Políticas de Confidencialidad
  sin necesidad de previo aviso, y se tienen por aceptados con el simple hecho
  de continuar siendo miembro del programa.
  <br /><br />
  Cláusula 2. Exención de responsabilidad: Bonavida no ofrece garantías de
  ningún tipo con respecto al resultado individual del usuario con relación a su
  participación en el programa. Asimismo manifiesta que que el contenido del
  sitio web, eventos presenciales y la documentación provista es creada de
  manera generalizada por profesionales en las diferentes ramas. Bonavida niega
  expresamente toda garantía explícita o implícita en relación a su programa y
  su utilización. Bonavida no asume obligación alguna por daños y perjuicios
  indirectos, cuantificables, emergentes, punitivos o especiales que surjan de
  la participación en el programa, independientemente de que las Partes hubiera
  o debieran haber tenido conocimiento de la posibilidad de tales daños. Además,
  el usuario conoce de manera exclusiva su condición médica y física, y exonera
  por este acto a Bonavida de cualquier responsabilidad respecto a lesiones,
  accidentes, enfermedades y consecuencias médicas o psicológicas, derivadas de
  la participación en el programa.
  <br /><br />
  Cláusula 3. Uso de la cuenta: El programa se considera por acuerdo entre las
  partes como INSTRANSFERIBLE, por lo que queda terminantemente prohibido que
  una persona tercera no miembro utilice este sitio web y participe en el
  programa, así como utilizar el acceso o las contraseñas personales de la
  cuenta de manera abusiva o indebida sin la debida autorización por escrito de
  Bonavida. Bonavida se reserva el derecho de controlar la actividad en el sitio
  web y la participación en el programa, así como investigar toda forma de
  utilización no permitida y divulgar información pertinente en caso de que
  detecte un uso prohibido. Asimismo, niega expresamente toda responsabilidad
  relacionada con el uso que el Usuario haga de este sitio web infringiendo la
  legislación vigente o las prohibiciones establecidas en este sentido. El
  incumplimiento de lo establecido en esta cláusula o en cualquier otra
  contenida en el presente acuerdo puede dar lugar a que Bonavida restrinja el
  acceso al servicio e inicie las acciones que considere necesarias según las
  circunstancias del caso. Además, el usuario por este acto se compromete a
  participar en el programa y a utilizar la información provista, de manera
  personal, le queda terminantemente prohibido divulgar la misma por cualquier
  medio a terceras personas.
  <br /><br />
  Cláusula 4. Propiedad intelectual: Todo el contenido que forma parte del
  programa (sin limitación, sitio web, marcas, nombres comerciales, marcas de
  servicio, derechos de autor y logotipos) es propiedad de Bonavida, y se haya
  protegido por la legislación guatemalteca. El uso de este sitio web no otorga
  la potestad de adquirir derechos o titularidad alguna sobre la propiedad
  intelectual mencionada. El ejercicio de todos los derechos de propiedad
  intelectual está reservado a quienes son sus titulares. Queda terminantemente
  prohibido usar los elementos que conforman la propiedad intelectual de
  Bonavida sin contar con una autorización por escrito para tal fin. Se hace la
  aclaración que dentro de la presente protección, se debe tomar en cuenta que
  los logos de los aliados estratégicos son de su propiedad y Bonavida cuenta
  con la autorización para poder utilizarlos en su programa.
  <br /><br />
  Cláusula 5. De las Políticas de Confidencialidad: Por este acto, Bonavida se
  compromete al buen manejo y almacenamiento de la información personal,
  empresarial y de contacto provista por el usuario o la empresa contratante,
  quedando terminante prohibido divulgar a terceros para otros fines, salvo que
  se cuente con autorización expresa del titular de dicha información. Para
  cumplir con lo anterior, se utilizan medidas de seguridad razonables para
  proteger la confidencialidad de toda la información que está bajo el control
  de Bonavida y limita el acceso a la misma. Se utilizan prácticas de seguridad
  estándares en la industria para el cifrado de las transmisiones de datos y
  para el cifrado de la información almacenada. El sitio web puede contener
  enlaces a otros sitios, tales como Twitter y Facebook, entre otros. Al acceder
  a cualquiera de estos terceros sitios web, estará sujeto a los términos de las
  políticas de confidencialidad de dichos sitios. Asegúrese de revisar las
  políticas de confidencialidad de estos otros sitios web cuando los visite.
  <br /><br />
  Clausula 6. Divisibilidad: Las disposiciones de estos Términos y Condiciones
  de Uso son divisibles entre sí. En el supuesto de que un juez competente
  declarase la nulidad o inexigibilidad de alguna de ellas, tal decisión no
  alterará la validez de las demás disposiciones.
  <br /><br />
  Cláusula 7. Competencia y legislación aplicable: Estos Términos y Condiciones
  de Uso se regirán por la legislación de Guatemala, y toda controversia que
  surja en relación con las disposiciones del presente acuerdo se deberá
  ventilar ante juez competente en ese territorio.
  <br /><br />
  Cláusula 8. Acuerdo íntegro: Estos Términos y condiciones constituyen el
  acuerdo íntegro entre las Partes en relación con el objeto contractual
  indicado en estas disposiciones.
  <br /><br />
  Cláusula 9. Duración del Programa: Por este acto, acepto y estoy de acuerdo,
  como usuario, que el programa de Bonavida tiene una duración mínima de un año,
  es decir 12 meses a partir de la fecha de inscripción en el programa. Por lo
  que cualquier incumplimiento a este término es sujeto a una penalidad.
  <br /><br />
  Cláusula 10. Recopilamos datos de pasos (Google Fit /Apple Health / Health
  Connect) desde tu teléfono y lo utiliza para ayudarte a mejorar tu salud. no
  compartimos sus datos con terceros. No vendemos tus datos. No utilizamos tu
  datos para publicidad. No utilizamos sus datos para ningún otro fin que no sea
  el de brindarle la mejor experiencia posible a través de desafíos de pasos
  diarios y otras características de salud y bienestar. Los datos se almacenan
  en nuestros servidores para el uso exclusivo de nuestro propósito. Permanecerá
  en nuestro servidor a menos que recibamos un Solicitud de supresión de datos
  por parte del usuario. Si desea eliminar su cuenta o cualquier datos asociados
  con su cuenta por favor envíenos un correo electrónico a
  soporte@bonavidawellness.com.
</div>
