import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pillar',
  templateUrl: './pillar.component.html',
  styleUrls: ['./pillar.component.css']
})
export class PillarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
