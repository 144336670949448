<section class="section discover-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Discover Thumb -->
                <div class="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                    <img src="assets/img/discover_thumb.png" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-5 order-1 order-lg-2">
                <!-- Discover Text -->
                <div class="discover-text pt-4 pt-lg-0">
                    <h2 class="pb-4 pb-sm-0">7 pilares de Wellness</h2>
                    <p class="d-none d-sm-block pt-3 pb-4">Wellness es mucho más que salud física, ejercicio y
                        nutrición. En Bonavida, el término Wellness es multidimensional. Estamos convencidos que
                        Wellness es buscar un balance personal y crecimiento continuo basado en los 7 pilares que hacen
                        posible el bienestar integral de cada persona. Cada uno de estos pilares no sólo contribuye a
                        mejorar la calidad de vida de las personas sino también se entrelazan y permiten que podamos
                        alcanzar nuestra mejor versión.
                    </p>
                    <!-- Check List -->
                    <ul class="check-list">
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">1</span>
                                <span class="media-body pl-2">Físico</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">2</span>
                                <span class="media-body pl-2">Mental</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">3</span>
                                <span class="media-body pl-2">Emocional</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">4</span>
                                <span class="media-body pl-2">Social</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">5</span>
                                <span class="media-body pl-2">Espiritual</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">6</span>
                                <span class="media-body pl-2">Ambiental</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <div class="list-box media">
                                <span class="round-bullet text-center text-white bg-gradient rounded-circle">7</span>
                                <span class="media-body pl-2">Ocupacional</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>