import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  email = "";
  message = "";
  isSending = false;
  isSent = false;
  constructor(public http: HttpClient) { }

  ngOnInit(): void {
  }


  sendMail() {
    if (this.isValidEmail(this.email)) {
      this.isSending = true;
      this.isSent = false;
      this.post({ email: this.email, message: this.message }).subscribe(data => {
        this.isSending = false;
        this.isSent = true;
      })
    } else {
      alert("Invalid email");
    }

  }

  isValidEmail(emailString: string): boolean {
    try {
      let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
      let valid = pattern.test(emailString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }



  post(data: any) {
    data = new URLSearchParams(data).toString();
    return this.http.post("https://4nw0d165z7.execute-api.us-east-2.amazonaws.com/Prod/send", data);
  }

}
