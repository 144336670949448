<section id="features" class="section bg-gray features-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Nuestra Solución Digital</h2>
                    <p class="d-none d-sm-block mt-4">Tu organización crece cuando tus colaboradores crecen. Nuestra
                        innovadora tecnología especializada en Wellness te permitirá promover la salud preventiva, el
                        bienestar integral, aumentar el nivel de compromiso, rendimiento e integración de los
                        colaboradores a través de una única y poderosa solución digital, capaz de transformar
                        positivamente la vida de los colaboradores.
                    </p>
                </div>
            </div>
        </div>
        <div class="vimeo-player">
            <iframe class="vimeo-iframe" src="https://player.vimeo.com/video/746692224?h=42cdd757d7" width="640"
                height="360" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Features Item -->
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s"
                            data-wow-delay="0.3s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_1.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Integral</h3>
                                <p>Utilizamos nuestro modelo de los 7 pilares de Wellness para ofrecer una experiencia
                                    integral y personalizada que se adapte a las necesidades e intereses de cada
                                    colaborador.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s"
                            data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_2.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Cultura de Salud, Bienestar e Integración</h3>
                                <p>Ofrece a los colaboradores una valiosa razón para integrarse de manera saludable y
                                    motivadora. Nuestra variedad de actividades, competencia sana y retos de Wellness
                                    permiten la unión entre equipos y departamentos sin importar su ubicación
                                    geográfica.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s"
                            data-wow-delay="0.9s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_3.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Gamification</h3>
                                <p>Con nuestro sistema de acumulación de puntos, los colaboradores pueden desbloquear y
                                    ganar recompensas mientras avanzan en su camino de bienestar integral. Asimismo
                                    podrán competir con sus compañeros para llegar a la cima de los distintos retos de
                                    Wellness.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-block">
                <!-- Featutes Thumb -->
                <div class="features-thumb text-center">
                    <img src="assets/img/features_thumb.png" alt="">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Features Item -->
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s"
                            data-wow-delay="0.3s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_4.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Aprendizaje</h3>
                                <p>Lecciones diarias que le permiten a los colaboradores una comprensión profunda de las
                                    7 partes de Wellness que los lleve a la acción. Desde lecciones de salud mental,
                                    hasta fortalecimiento emocional, nutrición, productividad, finanzas personales,
                                    mindfulness y mucho más.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s"
                            data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_5.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Acondicionamiento físico</h3>
                                <p>Variedad de entrenamientos físicos que se adaptan a cualquier horario y nivel de
                                    condición física. Los colaboradores podrán elegir ponerse en forma desde casa sin
                                    necesidad de equipo o bien hacerlo fuera de casa con mancuernas y bandas. Todas las
                                    opciones al alcance de sus manos.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s"
                            data-wow-delay="0.9s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_6.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Servicios Personalizados</h3>
                                <p>Ofrece a los colaboradores la opción de adquirir variedad de servicios personalizados
                                    con nutricionistas, psicólogos, psiquiatras, coaches de fitness, coaches de
                                    wellness, entre otros. En Bonavida somos expertos en Wellness y estamos para
                                    ayudarlos a vivir bien y alcanzar su mejor versión.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>