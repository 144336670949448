<section class="section work-area bg-overlay overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Work Content -->
                <div class="work-content text-center">
                    <h2 class="text-white">¿Cómo funciona Bonavida?</h2>
                    <p class="text-white my-3 mt-sm-4 mb-sm-5">Un enfoque de wellness integral y personalizado, que de
                        forma guiada, progresiva e inclusiva unirá a los colaboradores y los mantendrá saludables,
                        motivados y retados a ser su mejor versión.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_1.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Instalar la Bonavida App en la iOS o Android</h3>
                    <p class="d-none d-sm-block text-white pb-10">Encuentra la Bonavida App en la iOS o Android App
                        store y descárgala.
                    </p>
                    <p class="d-block d-sm-none text-white">Encuentra la Bonavida App en la iOS o Android App store y
                        descárgala.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_2.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Completa la evaluación de Wellness inicial</h3>
                    <p class="text-white">Ingresa con tus credenciales y completa la evaluación de inicio para que
                        podamos analizar tu perfil y ofrecerte recomendaciones con base a tus necesidades. </p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_3.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Sigue las indicaciones de BonaGuía</h3>
                    <p class="text-white">BonaGuía es tu guía hacia una vida llena de salud y bienestar. Sigue las
                        recomendaciones que BonaGuía te ofrece y disfruta del acompañamiento y todos sus beneficios.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>