<section id="review" class="review-area ptb_100 bg-gray">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        Conoce lo que opinan nuestros clientes
                    </span>
                    <h2 class="text-capitalize">En Bonavida ❤️ a nuestros clientes</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Una programa que también integra a la familia</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Antes de Bonavida llevaba una vida muy sedentaria. Ahora con mi familia, salimos los
                                fines de semana a algunos lugares a caminar e incluso subir volcanes y montañas.
                            </p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <!-- <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-3.png" alt="">
                        </div> -->
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <!-- <h5 class="reviewer-name color-primary mb-2">Anonymous</h5> -->
                            <h6 class="text-secondary fw-6">Colaboradora en Guatemala </h6>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Un programa a la medida de tus necesidades</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Me gusta la flexibilidad de desarrollar hábitos saludables según mi interés y tiempo
                                disponible. La Bonavida App es muy completa y motivadora, me ayuda a cuidar de mi salud
                                de manera integral. ¡Gracias Bonavida!
                            </p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <!-- <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-3.png" alt="">
                        </div> -->
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <!-- <h5 class="reviewer-name color-primary mb-2">Anonymous</h5> -->
                            <h6 class="text-secondary fw-6">Colaborador en Costa Rica</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Un estilo de vida saludable y lleno de gratitud</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Con este programa me he sentido totalmente increíble, veo una gran diferencia en mi
                                estilo de vida, en mis hábitos, en mi forma de ver la vida. Por todo eso día a día
                                celebro cada paso que doy. Gracias por este maravilloso programa.
                            </p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <!-- <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-3.png" alt="">
                        </div> -->
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <!-- <h5 class="reviewer-name color-primary mb-2">Anonymous</h5> -->
                            <h6 class="text-secondary fw-6">Colaborador en México</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Nuestro aliado en Wellness</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>A lo largo de los años y en especial a raíz de la pandemia, Bonavida ha sido nuestro
                                aliado para ayudarnos a mantener a nuestros colaboradores motivados e integrados, y a
                                guiarlos en cuidar activamente de su salud y bienestar de forma integral. El éxito de
                                una empresa depende del bienestar de sus colaboradores. Hemos sido testigos de ello.
                                Definitivamente recomiendo este programa.
                            </p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <!-- <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-1.png" alt="">
                        </div> -->
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <!-- <h5 class="reviewer-name color-primary mb-2">Gerente General en Centro América</h5> -->
                            <h6 class="text-secondary fw-6">Gerente General en Centro América</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Te ayudamos a sentirte mejor y con energía</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Quiero comentarles que me siento muy bien con este estupendo programa, cada día que pasa
                                me siento mejor, con mayor vitalidad y energía. Me encanta caminar y seguir con mis
                                hábitos. Quiero compartirles que varios compañeros quieren integrarse a este programa de
                                Bienestar porque me han dicho que qué estoy haciendo porque me veo muy bien. ¡Gracias
                                por este estupendo programa!</p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <!-- <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-2.png" alt="">
                        </div> -->
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <!-- <h5 class="reviewer-name color-primary mb-2">Anonymous</h5> -->
                            <h6 class="text-secondary fw-6">Colaboradora en México</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>